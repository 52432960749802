import type { SavedSearchResponse } from '@/interfaces/savedSearches/savedSearches'

import { RequestMethods } from '@/interfaces/api/requestMethods'
import useAuthenticatedQuery from '@/hooks/useAuthenticatedQuery'
import { savedSearchesCountURL } from '@/constants/apiURLs'

export type SavedSearchCount = {
  [key: number]: number | undefined
}

const useSavedSearchCount = (savedSearchIds: number[]) => {
  const savedSearchCount = {} as SavedSearchCount

  const {
    isPending: isSavedSearchCountLoading,
    isRefetching: isRefetchingSavedSearch,
    data = [],
    ...props
  } = useAuthenticatedQuery<unknown, unknown, SavedSearchResponse[]>(
    [RequestMethods.GET],
    RequestMethods.GET,
    savedSearchesCountURL(savedSearchIds || []),
    {
      queryKey: ['savedSearches', ...(savedSearchIds || [])],
      enabled: savedSearchIds.length > 0,
    }
  )

  if (data && Array.isArray(data)) {
    data.forEach((savedSearch) => {
      savedSearchCount[savedSearch.id] = savedSearch.newResultCount || undefined
    })
  }

  return { savedSearchCount, isSavedSearchCountLoading, isRefetchingSavedSearch, ...props }
}

export default useSavedSearchCount
