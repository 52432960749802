import type { PendingOffer, PendingOfferResponse } from '@/interfaces/offer/pendingOffer'

import { RequestMethods } from '@/interfaces/api/requestMethods'
import { userQueryKeys } from '@/interfaces/user/userQueries'
import useAuthenticatedQuery from '@/hooks/useAuthenticatedQuery'
import { pendingOffersURL } from '@/constants/apiURLs'

const usePendingOffers = () => {
  const {
    data: pendingOffers = [],
    refetch: fetchPendingOffers,
    isLoading: isPendingOffersLoading,
    isError: isPendingOffersError,
  } = useAuthenticatedQuery<PendingOfferResponse>([RequestMethods.GET], RequestMethods.GET, pendingOffersURL, {
    queryKey: [userQueryKeys.GetPendingOffers],
    initialData: [],
  })

  const totalPendingOffers =
    !isPendingOffersError && Array.isArray(pendingOffers)
      ? pendingOffers.reduce((total: number, current: PendingOffer) => total + current.offerCount, 0)
      : 0

  return { pendingOffers, fetchPendingOffers, isPendingOffersLoading, totalPendingOffers }
}

export default usePendingOffers
