import type { FC, PropsWithChildren } from 'react'
import type { Session } from 'next-auth'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SessionProvider } from 'next-auth/react'

import CartProvider from '@/store/cartProvider'
import ProductDataProvider from '@/store/productDataProvider'
import UserProvider from '@/store/userProvider'
import ItemImageDialogProvider from '@/store/itemImageDialogProvider'
import UserSettingsProvider from '@/store/userSettingsProvider'
import StoreCreditProvider from '@/store/storeCreditProvider'
import ItemDetailsProvider from '@/store/itemDetailsProvider'
import SavedSearchesProvider from '@/store/savedSearchesProvider'
import TelemetryProvider from './TelemetryProvider'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: false },
  },
})

interface AppContextProviderProps extends PropsWithChildren {
  session: Session
}

/**
 * Provides the context providers for the app.
 */
const AppContextProvider: FC<AppContextProviderProps> = ({ session, children }) => {
  return (
    <SessionProvider session={session}>
      <TelemetryProvider>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <UserSettingsProvider>
              <SavedSearchesProvider>
                <ProductDataProvider>
                  <ItemImageDialogProvider>
                    <CartProvider>
                      <ItemDetailsProvider>
                        <StoreCreditProvider>{children}</StoreCreditProvider>
                      </ItemDetailsProvider>
                    </CartProvider>
                  </ItemImageDialogProvider>
                </ProductDataProvider>
              </SavedSearchesProvider>
            </UserSettingsProvider>
          </UserProvider>
        </QueryClientProvider>
      </TelemetryProvider>
    </SessionProvider>
  )
}

export default AppContextProvider
